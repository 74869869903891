<template>
<div class="relative">
  <div class="vx-navbar-wrapper navbar-full p-0 "  :key="componentKey">
<!--    :style="navbarStyle"-->
<!--      :color="navbarColor"-->
    <div
      class="bg-white flex flex-row justify-between px-9 py-1 text-xl">
      <div class="flex flex-row pr-4 text-xl">
        <p class="">
          <strong class="pr-2">{{ $t('Welcome') }}</strong>
          <span class="text-lg">{{ activeUserInfo.organisation_name }}</span>
          <span class="text-lg">(<small>{{ activeUserInfo.customer_id  }}</small>)</span>
        </p>
      </div>
      <div class="flex flex-row text-lg">
        <div class="flex flex-row" v-if="hasCreditAndBalances">
          <div class="flex flex-row pr-2"
               style="text-align: end;white-space: nowrap;"
               :class="[
                 {
                   'text-warning': activeUserInfo.template != 5,
                   'text-black': activeUserInfo.template == 5
                 }
               ]">
            <span style="">{{$t('Credit')}}</span>
            <p class="px-2"><strong>{{activeUserInfo.credit | germanNumberFormat}}</strong></p>
          </div>
          <div v-if="activeUserInfo.template == 5" class="flex flex-row px-2 text-black"
               style="text-align: end; white-space: nowrap;" >
            <span style="">{{$t('Balance')}}</span>
            <p class="px-2"><strong>{{activeUserInfo.balance[activeUserInfo.balance_type] | germanNumberFormat}}</strong></p>
          </div>
          <div v-if="activeUserInfo.template != 5" class="flex flex-row px-2"
               style="text-align: end; white-space: nowrap;"
               :class="[activeUserInfo.balance[activeUserInfo.balance_type] < 0 ? 'text-danger' : 'text-success']">
            <span style="">{{$t('Balance')}}</span>
            <p class="px-2"><strong>{{activeUserInfo.balance[activeUserInfo.balance_type] | germanNumberFormat}}</strong></p>
          </div>
          <div  class="flex flex-row px-2"
                :class="[
                 {
                   'text-warning': activeUserInfo.template != 5,
                   'text-black': activeUserInfo.template == 5
                 }
               ]"
                style="text-align: end; white-space: nowrap;"
                v-if="activeUserInfo.account_role >= 2">
            <span style="">{{$t('Sales')}}</span>
            <p class="px-2"><strong>{{activeUserInfo.today_balance | germanNumberFormat}}</strong></p>
          </div>
          <!--        <div class="p-2" v-if="activeUserInfo.account_role >= 2"></div>-->
          <!--        <div style="text-align: end; color: rgba(var(--vs-warning));white-space: nowrap;" v-if="activeUserInfo.account_role >= 2">-->
          <!--          <small style="">{{$t('TotalBuyingAmount')}}</small>-->
          <!--          <p class="px-2"><strong>{{activeUserInfo.total_buying_amount | germanNumberFormat}}</strong></p>-->
          <!--        </div>-->
          <router-link to="/seller/sales" >
            <div class="flex flex-row px-2"
                 :class="[
                   {
                     'text-primary': activeUserInfo.template != 5,
                     'text-black': activeUserInfo.template == 5
                   }
                 ]"
                 style="text-align: end; white-space: nowrap;"
                 v-if="activeUserInfo.account_role >= 2">
              <span style="">{{$t('SalesCount')}}</span>
              <p class="px-2"><strong>{{activeUserInfo.today_sale_count }}</strong></p>
            </div>
          </router-link>
        </div>
        <i18n />
      </div>
    </div>
    <vs-navbar class="navbar-custom navbar-skelton bg-white" :class="navbarClasses" :color="'#ffffff'">

      <a @click="checkOpenPage" class="vx-logo cursor-pointer mx-auto items-center">
<!--        <logo class="w-10 mr-4 filcurrent text-primary" />-->
        <img :src="getlogo()" class="w-10 mr-4 filcurrent text-primary"/>
<!--        <span class="vx-logo-text text-primary">PJ Telesoft</span>-->
      </a>

      <horizontal-nav-menu-modified class="ml-3" :class="[
      {'text-white' : !isThemedark},
      {'text-base'  : isThemedark}
      ]"
      :navMenuItems="navItems" ></horizontal-nav-menu-modified>

      <vs-spacer></vs-spacer>

      <div class="p-2"></div>
      <profile-drop-down />
<!--      <div class="p-2"></div>-->
<!--      <i18n />-->

    </vs-navbar>
  </div>

<!--  <div class="m-6">-->
<!--    <h3 class="py-4">Contact</h3>-->
<!--    <a target="_blank" href="tel:+49021186042150"><p class="py-2"><strong>Tel: +49 (0) 211 8604 2150</strong></p></a>-->
<!--    <a target="_blank" href="tel:+4903055218000"><p class="py-2"><strong>Tel: +49 (0) 30 5521 8000</strong></p></a>-->
<!--    <a target="_blank" href="tel:+4908974049060"><p class="py-2"><strong>Tel: +49 (0) 89 74 049 060</strong></p></a>-->
<!--    <a target="_blank" href="https://wa.me/4915779130000"><p class="py-2"><strong>WhatsApp:  +4915779130000</strong></p></a>-->
<!--    <p class="py-6"></p>-->
<!--  </div>-->
</div>
</template>

<script>
import HorizontalNavMenuModified from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenuModified.vue'
import * as adminNav from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import * as sellerNav from '@/layouts/components/vertical-nav-menu/sellerNavMenuItems.js'
import * as resellerNav from '@/layouts/components/vertical-nav-menu/resellerNavMenuItems.js'
import Logo from '../Logo.vue'
import ProfileDropDown from './components/ProfileDropDown.vue'

import I18n from './components/I18n.vue'

export default {
  name: 'the-navbar-horizontal',
  data() {
    return {
      navItems: {},
      componentKey: 0,
    }
  },
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true,
    },
  },
  components: {
    Logo,
    ProfileDropDown,
    I18n,
    HorizontalNavMenuModified,
  },
  computed: {
    navbarColor() {
      let color = '#fff'
      if (this.navbarType === 'sticky') color = '#fff'
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = '#f7f7f7'
        }
      }

      if (this.isThemedark === 'dark') {
        if (color === '#fff') {
          color = '#10163a'
        } else {
          color = '#262c49'
        }
      }

      return color
    },
    isThemedark() { return this.$store.state.theme },
    navbarStyle() { return this.navbarType === 'static' ? { transition: 'all .25s ease-in-out' } : {} },
    navbarClasses() { return this.scrollY > 5 && this.navbarType === 'static' ? null : 'd-theme-dark-light-bg shadow-none' },
    scrollY() { return this.$store.state.scrollY },
    verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth },
    windowWidth() { return this.$store.state.windowWidth },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    getNavMenuItems() {
      const role = this.activeUserInfo.account_role
      switch (role) {
      case 0:
        adminNav.reload()
        return adminNav.navMenuItems
      case 1:
        resellerNav.reload()
        return resellerNav.navMenuItems
      case 2:
        sellerNav.reload()
        return sellerNav.navMenuItems
      case 3:
        adminNav.reload()
        return adminNav.navMenuItems
      default:
        return []
      }
    },
    hasCreditAndBalances() {
      const role = this.activeUserInfo.account_role
      const irole = this.activeUserInfo.internal_role
      switch (role) {
      case 1:
        if(irole == 0) {
          return true
        } else {
          return false
        }
      case 2:
        return true
      case 3:
        return true
      default:
        return false
      }
    },
  },
  watch: {
    activeUserInfo (newValue, oldValue) {
      this.updateUser()
    },
    '$store.state.AppActiveUser': function() {
      this.updateUser()
    }
  },
  methods: {
    updateUser() {
      this.navItems = this.getNavMenuItems;
      this.componentKey += 1;
    },
    checkOpenPage() {
      if (localStorage.getItem('accessToken') && localStorage.getItem('userInfo')) {
        this.$router.push('/dashboard')
      } else {
        this.$router.push('/login')
      }
    },
    getlogo() {
      return JSON.parse(localStorage.getItem('home')).logo
    },
  },
  mounted() {
    this.updateUser()
  }
}

</script>
<style>
</style>

