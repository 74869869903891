var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{key:_vm.componentKey,staticClass:"vx-navbar-wrapper navbar-full p-0 "},[_c('div',{staticClass:"bg-white flex flex-row justify-between px-9 py-1 text-xl"},[_c('div',{staticClass:"flex flex-row pr-4 text-xl"},[_c('p',{},[_c('strong',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$t('Welcome')))]),_c('span',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.activeUserInfo.organisation_name))]),_c('span',{staticClass:"text-lg"},[_vm._v("("),_c('small',[_vm._v(_vm._s(_vm.activeUserInfo.customer_id))]),_vm._v(")")])])]),_c('div',{staticClass:"flex flex-row text-lg"},[(_vm.hasCreditAndBalances)?_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"flex flex-row pr-2",class:[
                 {
                   'text-warning': _vm.activeUserInfo.template != 5,
                   'text-black': _vm.activeUserInfo.template == 5
                 }
               ],staticStyle:{"text-align":"end","white-space":"nowrap"}},[_c('span',{},[_vm._v(_vm._s(_vm.$t('Credit')))]),_c('p',{staticClass:"px-2"},[_c('strong',[_vm._v(_vm._s(_vm._f("germanNumberFormat")(_vm.activeUserInfo.credit)))])])]),(_vm.activeUserInfo.template == 5)?_c('div',{staticClass:"flex flex-row px-2 text-black",staticStyle:{"text-align":"end","white-space":"nowrap"}},[_c('span',{},[_vm._v(_vm._s(_vm.$t('Balance')))]),_c('p',{staticClass:"px-2"},[_c('strong',[_vm._v(_vm._s(_vm._f("germanNumberFormat")(_vm.activeUserInfo.balance[_vm.activeUserInfo.balance_type])))])])]):_vm._e(),(_vm.activeUserInfo.template != 5)?_c('div',{staticClass:"flex flex-row px-2",class:[_vm.activeUserInfo.balance[_vm.activeUserInfo.balance_type] < 0 ? 'text-danger' : 'text-success'],staticStyle:{"text-align":"end","white-space":"nowrap"}},[_c('span',{},[_vm._v(_vm._s(_vm.$t('Balance')))]),_c('p',{staticClass:"px-2"},[_c('strong',[_vm._v(_vm._s(_vm._f("germanNumberFormat")(_vm.activeUserInfo.balance[_vm.activeUserInfo.balance_type])))])])]):_vm._e(),(_vm.activeUserInfo.account_role >= 2)?_c('div',{staticClass:"flex flex-row px-2",class:[
                 {
                   'text-warning': _vm.activeUserInfo.template != 5,
                   'text-black': _vm.activeUserInfo.template == 5
                 }
               ],staticStyle:{"text-align":"end","white-space":"nowrap"}},[_c('span',{},[_vm._v(_vm._s(_vm.$t('Sales')))]),_c('p',{staticClass:"px-2"},[_c('strong',[_vm._v(_vm._s(_vm._f("germanNumberFormat")(_vm.activeUserInfo.today_balance)))])])]):_vm._e(),_c('router-link',{attrs:{"to":"/seller/sales"}},[(_vm.activeUserInfo.account_role >= 2)?_c('div',{staticClass:"flex flex-row px-2",class:[
                   {
                     'text-primary': _vm.activeUserInfo.template != 5,
                     'text-black': _vm.activeUserInfo.template == 5
                   }
                 ],staticStyle:{"text-align":"end","white-space":"nowrap"}},[_c('span',{},[_vm._v(_vm._s(_vm.$t('SalesCount')))]),_c('p',{staticClass:"px-2"},[_c('strong',[_vm._v(_vm._s(_vm.activeUserInfo.today_sale_count))])])]):_vm._e()])],1):_vm._e(),_c('i18n')],1)]),_c('vs-navbar',{staticClass:"navbar-custom navbar-skelton bg-white",class:_vm.navbarClasses,attrs:{"color":'#ffffff'}},[_c('a',{staticClass:"vx-logo cursor-pointer mx-auto items-center",on:{"click":_vm.checkOpenPage}},[_c('img',{staticClass:"w-10 mr-4 filcurrent text-primary",attrs:{"src":_vm.getlogo()}})]),_c('horizontal-nav-menu-modified',{staticClass:"ml-3",class:[
      {'text-white' : !_vm.isThemedark},
      {'text-base'  : _vm.isThemedark}
      ],attrs:{"navMenuItems":_vm.navItems}}),_c('vs-spacer'),_c('div',{staticClass:"p-2"}),_c('profile-drop-down')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }